import { ApolloProvider } from '@apollo/client'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import BackgroundParticles from 'components/BackgroundParticles'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Landing from 'components/Landing'
import Register from 'components/Register'
import SectionHowItWorks from 'components/Section/SectionHowItWorks'
import SectionPayment from 'components/Section/SectionPayment'
import SectionWeManageAll from 'components/Section/SectionWeManageAll'
import SectionWhatNow from 'components/Section/SectionWhatNow'
import { client } from 'graphql/client'
import ReactDOM from 'react-dom'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'styles/global'
import muiTheme from 'styles/muiTheme'
import theme from 'styles/theme'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <BackgroundParticles />
            <GlobalStyles />
            <Header />
            <Landing />
            <div style={{ padding: 20 }}>
              <SectionHowItWorks />
              <SectionWhatNow />
              <SectionPayment />
            </div>
            <SectionWeManageAll />
            <Register />
            <Footer />
          </ThemeProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    )
  }
])

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
)
