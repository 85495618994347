import { gql } from '@apollo/client'

export const CREATE_SUPPLIER = gql`
  mutation CREATE_SUPPLIER($supplierInput: SupplierInput!) {
    payload: createSupplier(data: $supplierInput) {
      data {
        attributes {
          createdAt
        }
      }
    }
  }
`
