import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material'
import { ForwardRefRenderFunction, forwardRef } from 'react'
import * as S from './styles'

const Checkbox: ForwardRefRenderFunction<HTMLButtonElement, CheckboxProps> = (
  props,
  ref
) => {
  return (
    <MuiCheckbox
      {...props}
      ref={ref}
      icon={<S.Icon />}
      checkedIcon={<S.CheckedIcon />}
    />
  )
}
export default forwardRef(Checkbox)
